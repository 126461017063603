body {
  background-color: #F5F1ED!important;
}

.bg-body-tertiary.justify-content-between.navbar.navbar-expand.navbar-light{
  background-color: #e4efd0!important;
  border-radius: 1em;
}

.veryBig {
    font-size: 5em;
    margin-bottom: 0!important;
}

.middleSize {
    font-size: 3em;
    margin-top: 0!important;
}

.immagineDettaglio {
    max-width: 100%;
}

.titoloCity {
    font-size: 7em;
    font-family: "Lexend Deca", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
}

.bottoneChiudi {
    font-size: 2em;
    position: fixed;
    top: 0;
}

.blueTemperature {
    color: blue;
  }
  
  .yellowTemperature {
    color: rgb(234,182,118);
  }
  
  .redTemperature {
    color: #e23e52;
  }

  .lostWords {
    background: rgb(137,191,0);
    background: radial-gradient(circle, rgba(137,191,0,0.9401961468181023) 0%, rgba(217,179,68,1) 100%); 
    font-size: 5em;
    padding: 1.5em;
    border-radius: 3em;
    text-align: center;
    color: rgb(33, 50, 36)
  }

  .singleElementList {
    cursor: pointer;
  }

  .singleElementList:hover {
    background-color: rgba(173, 216, 230, 0.8); 
  }

  /* Tablet */
@media (max-width: 768px) {
  .veryBig {
      font-size: 4em;
  }

  .middleSize {
      font-size: 2.5em;
  }

  .titoloCity {
      font-size: 5em;
  }

  .bottoneChiudi {
      font-size: 1.5em;
  }

}

/* Mobile */
@media (max-width: 480px) {
  .veryBig {
      font-size: 2.5em;
  }

  .middleSize {
      font-size: 1.8em;
  }

  .titoloCity {
      font-size: 3.5em; 
  }

  .bottoneChiudi {
      font-size: 1.2em;
  }

}
